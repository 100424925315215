import React from "react";
import LayoutWrapper from "../component/LayoutWrapper/Index";
import BlogsPosts from "../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../component/Blog/ShareLinks";
import AuthorAvatar from "../component/Blog/AuthorAvatar";
import CommonParagraph from "../component/Blog/CommonParagraph";
import Heading from "../component/Blog/Heading";
import BlogLink from "../component/Blog/BlogLink";
import { blogList } from "../data/blogs";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ContactSlideoutLink from "../component/Blog/ContactSlideoutLink";

const BlogPost = () => {
  const blogData = blogList.filter((blog) => blog.url == "/golden-hill-affordable-artistic-living");

  return (
    <LayoutWrapper
      title={blogData[0].title}
      desc={blogData[0].metaDescription}
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#020101] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              {blogData[0].title}
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <p className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]">
                  By {blogData[0].author}
                </p>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">
                  {blogData[0].date}
                </p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../images/The Selby Team_Golden Hill_ Affordable Artistic Living (Premium).jpg"
              alt="blogpost"
            />
            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <CommonParagraph>
                Nestled east of downtown San Diego lies Golden Hill, a vibrant neighborhood alive
                with creativity and energy. This unique community is not only a safe haven for
                artists; it's also affordable.
              </CommonParagraph>
              <CommonParagraph>
                If you're seeking a culturally rich lifestyle that inspires without breaking the
                bank, you can find affordable artistic living in Golden Hill.
              </CommonParagraph>
              <Heading type="h2">The Charm of Golden Hill</Heading>
              <CommonParagraph>
                With tree-lined streets, diverse architectural styles, and a palpable sense of
                community spirit, it's no wonder Golden Hill is beloved by its residents. With
                sprawling green spaces, delicious culinary delights, and various outdoor activities,
                it's a haven for growing families, working professionals, and retirees. However, the
                thriving arts scene and{" "}
                <BlogLink
                  url="https://selbysellssd.com/buy-house-san-diego/"
                  text="affordable housing opportunities"
                />{" "}
                truly make Golden Hill shine.
              </CommonParagraph>
              <Heading type="h2">Its Commitment to Creatives</Heading>
              <CommonParagraph>
                This neighborhood doesn't only embrace artists and creatives; it actively supports
                them and showcases their work. Whether exploring hidden alleys or strolling down
                Broadway, you're bound to come upon sculptures, installations, and colorful murals
                that bring Golden Hill to life in a fresh, new way. Galleries, art spaces, and
                studies dot the neighborhoods, showing how committed this community is to creatives
                and how much they appreciate artistry.
              </CommonParagraph>
              <Heading type="h2">A Collaborative Community</Heading>
              <CommonParagraph>
                Are you looking for a neighborhood that hosts regular workshops, art walks, and
                events that encourage dialogue among resident artists, visitors, and the community?
                Golden Hill offers the connection and collaboration you're looking for. There's
                always something creative to enjoy or participate in, whether a gallery opening or
                open mic night. Artists aren't simply living in Golden Hill; the community embraces
                them, and they've become an integral part of life.
              </CommonParagraph>
              <Heading type="h2">Inspiration Around Every Corner</Heading>
              <CommonParagraph>
                If you're not actively creating but are looking for inspiration, let this
                neighborhood be your muse! The vibrant culinary scene can spark your imagination as
                you sip artisanal coffee in a cozy cafe or indulge in exquisite international
                cuisine at a family-owned eatery. Unlike other nearby areas, tantalizing your taste
                buds doesn't cost an arm and a leg.
              </CommonParagraph>
              <CommonParagraph>
                Golden Hill is also home to several parks and open spaces for outdoor recreational
                activities. Take a break from your artistry with a yoga session, picnic, or a walk
                through Golden Hill Park, which offers sweeping views of the city skyline. You'll
                feel rejuvenated and inspired with ample opportunities to reconnect with nature.
              </CommonParagraph>
              <Heading type="h2">Affordable Home Sweet Home</Heading>
              <CommonParagraph>
                Despite its growing popularity and prime location, Golden Hill remains surprisingly
                affordable, unlike other San Diego neighborhoods.{" "}
                <BlogLink
                  url="https://selbysellssd.com/san-diego-communities/golden-hill-homes-for-sale"
                  text="Housing options"
                />{" "}
                range from spacious apartments and cozy bungalows to modern townhouses and luxury
                homes, ensuring artists from all walks of life can find their perfect fit.
              </CommonParagraph>
              <CommonParagraph>
                By{" "}
                <BlogLink
                  url="https://selbysellssd.com/how-to-choose-a-real-estate-agent-in-san-diego/"
                  text="teaming up with a real estate agent"
                />{" "}
                well-versed in the Golden Hill community, you'll find options in your preferred
                pocket of town, whether in the heart of the arts scene, close to dining options, or
                a more serene environment.
              </CommonParagraph>
              <Heading type="h2">Flourish in Affordable Golden Hill</Heading>
              <CommonParagraph>
                As the cost of living rises across the country, Golden Hill stands tall as a beacon
                of affordability and creativity, making it the perfect place for economical,
                artistic living. With help from The Selby Team, break away from monotonous
                mainstream urban experiences and thrive in a creative paradise.
              </CommonParagraph>
              <CommonParagraph>
                Our experts will find the perfect Golden Hill home for you, allowing you to live
                your artistic life to the fullest without compromising your budget.{" "}
                <ContactSlideoutLink text="Contact us today" />!
              </CommonParagraph>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
